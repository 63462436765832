class FixedHeader {

    #element;

    constructor() {
        const header = $('.app-header');
        this.#element = header.clone();
        this.#element.addClass('is-fixed');
        header.after(this.#element);
    }

    update() {
        const html = $('html');
        const body = $('body');
        const viewport = window.innerHeight;
        const scrollTop = html.scrollTop();
        if (scrollTop >= (viewport * 0.4) && !body.hasClass('has-fixed-header')) {
            body.addClass('has-fixed-header');
        } else if (scrollTop < (viewport * 0.4) && body.hasClass('has-fixed-header')) {
            body.removeClass('has-fixed-header');
        }
        window.requestAnimationFrame(() => {
            this.update();
        });
    }
}

class App {

    #fixedHeader;

    constructor() {
        jQuery(document).ready(($) => {
            this.onDomReady($);
        });

        this.#fixedHeader = new FixedHeader();

        document.addEventListener('alpine:init', () => {
            Alpine.data('total', () => ({
                init() {
                    this.update();
                },
                update() {
                    const formatter = new Intl.NumberFormat('es-MX', {
                      style: 'currency',
                      currency: 'MXN',
                    });
                    const option = this.$refs.option;
                    this.$refs.amount.textContent = formatter.format(option.value);
                }
            }));

            Alpine.data('faq', () => ({
                toggle() {
                    const question = $(this.$el).closest('.question');
                    question.toggleClass('is-open').siblings('.question.is-open').removeClass('is-open');
                }
            }));

            Alpine.data('mobile', () => ({
                menu: null,
                init() {
                    if (! this.menu ) {
                        this.menu = document.querySelector('.menu-mobile');
                    }
                },
                openMenu() {
                    this.menu.classList.add('is-open');
                },
                closeMenu() {
                    this.menu.classList.remove('is-open');
                }
            }));
        });
    }

    onDomReady($) {
        this.#fixedHeader.update();

        $('.mfp-image').magnificPopup();
        $('.mfp-video').magnificPopup({
            type: 'iframe'
        });

        $('.swiper').each((index, el) => {
            const wrapped = $(el),
                delay = wrapped.data('delay') || 15000,
                mobile = wrapped.data('mobile') || 1,
                tablet = wrapped.data('tablet') || 2,
                slides = wrapped.data('slides') || 1;
            const swiper = new Swiper(el, {
                slidesPerView: mobile,
                breakpoints: {
                    768: {
                        slidesPerView: tablet,
                    },
                    1024: {
                        slidesPerView: slides,
                    }
                },
                loop: true,
                spaceBetween: 16,
                autoplay: {
                    delay: delay,
                    disableOnInteraction: true
                },
                pagination: {
                    el: '.swiper-pagination',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            });
        });
    }
}

window.app = window.app || new App();
